window.scrollTo(0, 0);

// constant
const MAP_RATIO = 0.75;
//
// events
const MOUSE_OVER = new MouseEvent("mouseover", {
  view: window,
  bubbles: true,
  cancelable: true
});
const MOUSE_LEAVE = new MouseEvent("mouseleave", {
  view: window,
  bubbles: true,
  cancelable: true
});
const CLICK = new MouseEvent("click", {
  view: window,
  bubbles: true,
  cancelable: true
});

// computed props
const GET_DEFAULT_MAP_WIDTH = () => {
  return window.innerWidth * MAP_RATIO > window.innerHeight
    ? (window.innerHeight / MAP_RATIO) * 0.8
    : window.innerWidth * 0.8;
};
const GET_DEFAULT_MAP_X = () =>
  (window.innerWidth - GET_DEFAULT_MAP_WIDTH()) / 2;
const GET_DEFAULT_MAP_Y = () =>
  (window.innerHeight - GET_DEFAULT_MAP_WIDTH() * MAP_RATIO) / 2;

const MOBILE_USER =
  typeof window.orientation !== "undefined" ||
  navigator.userAgent.indexOf("IEMobile") !== -1;
const TOUCH_ENABLED = MOBILE_USER || "touchstart" in document.documentElement;

// state
let ANIMATING_MAP = false;
let DRAGGING_MAP = false;
let ENABLE_ZOOM = false;
let MAP_CONTAINER_WIDTH = GET_DEFAULT_MAP_WIDTH();
let MAP_CONTAINER_X = 0;
let MAP_CONTAINER_Y = 0;
let MAP_REDRAWING = false;
let CURRENT_INDEX = 0;
let CURRENT_AUDIO = document.createElement("Audio");
// let CURRENT_AUDIO = null;
let STORY_INDEX = 0;
let TOUCH_X = 0;
let TOUCH_Y = 0;
let GUIDED_TOUR = false;

// document components

// page components
const navComponent = document.getElementById("nav_component");
const aboutComponent = document.getElementById("about_component");
const mapComponent = document.getElementById("map_component");
const storyComponent = document.getElementById("story_component");

// nav components
const navHeader = document.getElementById("nav_header");

// map components
const mapCanvas = document.getElementById("map_canvas");
const mapCanvasCtx = mapCanvas.getContext("2d");
const mapContainer = document.getElementById("map_container");
const mapMarkerContainer = document.getElementById("map_marker_container");
const mapMarkers = (() => {
  const list = [];
  const collection = document.getElementsByClassName("map_marker");

  for (let i = 0; i < collection.length; i++) {
    const m = collection[i];

    list.push({
      id: m.id,
      image: document.getElementById(`${m.id}_image`),
      lat: m.dataset.lat,
      lon: m.dataset.lon,
      storyTitle: m.dataset.title
    });
  }
  return list;
})();

// UI components
const mapUI = document.getElementById("map_ui");
const navUI = document.getElementById("nav_ui");
const playerUI = document.getElementById("player_ui");
const tooltip = document.getElementById("tooltip_ui");

// story components
const storyArticles = storyComponent.getElementsByTagName("article");

function clearCanvas() {
  return new Promise((resolve, reject) => {
    try {
      mapCanvasCtx.clearRect(0, 0, mapCanvas.width, mapCanvas.height);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
function drawImage(
  img,
  x = 0,
  y = 0,
  w = mapCanvas.width,
  h = mapCanvas.height,
  a = 1
) {
  return new Promise((resolve, reject) => {
    try {
      mapCanvasCtx.globalAlpha = a;
      mapCanvasCtx.drawImage(img, x, y, w, h);
      mapCanvasCtx.globalAlpha = 1;
      setTimeout(resolve, 1);
    } catch (e) {
      reject(e);
    }
  });
}
function loadImage(element) {
  return new Promise((resolve, reject) => {
    try {
      if (element.complete) {
        resolve();
      } else {
        element.addEventListener("load", () => {
          resolve();
        });
      }
    } catch (e) {
      reject(e);
    }
  });
}
function hideUI() {
  for (let i = 0; i < storyArticles.length; i++) {
    storyArticles[i].className = "";
  }
  playerUI.className = "hide";
  navComponent.className = "hide";
  aboutComponent.className = "hide";
  storyComponent.className = "hide";
}
function showUI() {
  function hideArticles() {
    return new Promise(resolve, reject => {
      for (let i = 0; i < storyArticles.length; i++) {
        storyArticles[i].className = "";
      }
    });
  }
  playerUI.className = "hide";
  navComponent.className = "hide";
  aboutComponent.className = "hide";
  storyComponent.className = "hide";
}
function loadAllImages() {
  const allImages = [].slice.call(document.images);

  return new Promise((resolve, reject) => {
    Promise.all(allImages.map(loadImage)).then(resolve).catch(reject);
  });
}
function resizeMap(w) {
  return new Promise((resolve, reject) => {
    try {
      MAP_SCALE = w / GET_DEFAULT_MAP_WIDTH();
      MAP_CONTAINER_WIDTH = w;

      // apply dimensions to canvas and container
      mapContainer.style.width = `${MAP_CONTAINER_WIDTH}px`;
      mapContainer.style.height = `${MAP_CONTAINER_WIDTH * MAP_RATIO}px`;
      mapCanvas.width = MAP_CONTAINER_WIDTH;
      mapCanvas.height = MAP_CONTAINER_WIDTH * MAP_RATIO;
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
function resetMapContainer() {
  return new Promise((resolve, reject) => {
    try {
      resizeMap(GET_DEFAULT_MAP_WIDTH())
        .then(() => placeMapContainer(GET_DEFAULT_MAP_X(), GET_DEFAULT_MAP_Y()))
        .then(resolve);
    } catch (e) {
      reject(e);
    }
  });
}

// drawing functions
function animateMap({ x, y, w, showMarkers = true }, t = 1000) {
  return new Promise((resolve, reject) => {
    if (!ANIMATING_MAP) {
      ANIMATING_MAP = true;
      const startX = MAP_CONTAINER_X;
      const startY = MAP_CONTAINER_Y;
      const startW = MAP_CONTAINER_WIDTH;
      const dx = x - startX;
      const dy = y - startY;
      const dw = w - startW;

      let starttime = null;

      function drawFrame(timestamp) {
        if (!starttime) starttime = timestamp;

        const progress =
          timestamp - starttime > t ? 1 : (timestamp - starttime) / t;

        clearCanvas()
          .then(() =>
            placeMapContainer(progress * dx + startX, progress * dy + startY)
          )
          .then(() => resizeMap(progress * dw + startW))
          .then(drawMap)
          .then(() => {
            if (showMarkers === true) placeMarkerButtons();
          });

        if (progress < 1) {
          window.requestAnimationFrame(drawFrame);
        } else {
          setTimeout(() => {
            ANIMATING_MAP = false;
            resolve();
          }, 30);
        }
      }

      window.requestAnimationFrame(drawFrame);
    }
  });
}
function createMarkerButtons() {
  return new Promise((resolve, reject) => {
    try {
      mapMarkerContainer.style.display = "none";

      for (let i = 0; i < mapMarkers.length; i++) {
        const m = mapMarkers[i];
        const storyImage = document.getElementById(`${m.id}_image`);
        const tooltipImage = storyImage.cloneNode();
        const button = document.createElement("button");
        button.id = `${m.id.slice(0, -6)}button`;
        const h =
          mapCanvas.width * 0.04 < 32
            ? 32
            : mapCanvas.width * 0.05 > 64
            ? mapCanvas.width * 0.05
            : 64;
        const w = h * 0.7;
        const left = m.lon * mapCanvas.width - w / 2;
        const top = m.lat * mapCanvas.height - h / 2;
        const storyArticle = document.getElementById(
          `${m.id.slice(0, -6)}story`
        );
        const storyAudio = document.getElementById(`${m.id.slice(0, -6)}audio`);
        const hideButton = document.createElement("button");

        storyArticle.insertAdjacentElement("afterbegin", hideButton);
        hideButton.className = "hide_article_button";
        hideButton.addEventListener("click", () => {
          resetMapContainer()
            .then(drawMap)
            .then(placeMarkerButtons)
            .then(() => {
              hideUI();
              mapUI.className = "";
              navUI.className = "";
              playerUI.className = "hide";
              storyComponent.className = "hide";
              playerUI.dataset.playing = "false";
              CURRENT_AUDIO.pause();
              storyArticle.style.marginTop = 0;
            });
        });

        button.style.top = `${top}px`;
        button.style.left = `${left}px`;
        button.style.height = `${h}px`;
        button.style.width = `${w}px`;

        button.addEventListener("mouseover", e => {
          button.dataset.tooltipTop =
            parseInt(button.style.top) + MAP_CONTAINER_Y;
          button.dataset.tooltipLeft =
            parseInt(button.style.left) +
            parseInt(button.style.width) / 2 +
            MAP_CONTAINER_X;
          tooltip.innerHTML = `<span>Listen to '${m.storyTitle}'</span>`;
          tooltipImage.style.height = `${Math.min(
            2.5 * parseInt(button.style.width),
            96
          )}px`;
          tooltip.appendChild(tooltipImage);
          mapMarkerContainer.className = "active";
          button.className = "active";

          const { height, width } = tooltip.getBoundingClientRect();

          setTimeout(() => {
            tooltip.style.top = `${button.dataset.tooltipTop - height}px`;
            tooltip.style.left = `${button.dataset.tooltipLeft - width / 2}px`;
            tooltip.style.opacity = 1;
          }, 10);
        });
        button.addEventListener("mouseleave", () => {
          tooltip.innerHTML = "";
          tooltip.style.opacity = 0;
          mapMarkerContainer.className = "";
          button.className = "";
        });
        button.addEventListener("click", () => {
          CURRENT_INDEX = parseInt(storyArticle.dataset.index);
          hideUI();
          playerUI.dataset.playing = "false";
          if (CURRENT_AUDIO) CURRENT_AUDIO.pause();
          tooltip.innerHTML = "";
          mapMarkerContainer.className = "";
          button.className = "";
          mapMarkerContainer.style.display = "none";

          let x =
            GET_DEFAULT_MAP_X() -
            GET_DEFAULT_MAP_WIDTH() * 0.5 -
            (m.lon - 0.4875) * GET_DEFAULT_MAP_WIDTH() * 2;
          let y =
            GET_DEFAULT_MAP_Y() -
            (GET_DEFAULT_MAP_WIDTH() * MAP_RATIO) / 2 -
            (m.lat - 0.4875) * (GET_DEFAULT_MAP_WIDTH() * MAP_RATIO * 2) -
            64;
          let w = GET_DEFAULT_MAP_WIDTH() * 2;
          let showMarkers = false;

          if (window.innerWidth > 800) {
            x -= 250;
            showMarkers = true;
          }
          animateMap(
            {
              x,
              y,
              w,
              showMarkers
            },
            500
          )
            .then(() => {
              return new Promise((resolve, reject) => {
                if (window.innerWidth > 800) {
                  placeMarkerButtons()
                    .then(() => {
                      button.style.display = "none";
                    })
                    .then(resolve);
                } else resolve();
              });
            })
            .then(() =>
              drawImage(
                storyImage,
                m.lon * mapCanvas.width - 64,
                m.lat * mapCanvas.height - 45,
                128,
                128
              )
            )
            .then(() => {
              setTimeout(() => {
                const navHeaderRect = navHeader.getBoundingClientRect();

                storyArticle.className = "show";
                storyArticle.style.marginTop = `${navHeaderRect.height}px`;
                mapUI.className = "hide";
                navUI.className = "hide";
                playerUI.className = "paused";
                storyComponent.className = "show";

                const playerUIRect = playerUI.getBoundingClientRect();

                storyArticle.style.height = `${
                  parseInt(window.innerHeight) -
                  parseInt(navHeaderRect.height) -
                  parseInt(playerUIRect.height) -
                  32
                }px`;

                CURRENT_AUDIO.src = storyAudio.src;
                CURRENT_AUDIO.load();
                playerUI.dataset.story_title = m.storyTitle;
                playerUI.dataset.playing = "false";
              }, 50);
            })
            .catch(alert);
        });
        mapMarkerContainer.appendChild(button);
      }
      mapMarkerContainer.style.display = "block";
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
function placeMarkerButtons() {
  return new Promise((resolve, reject) => {
    mapMarkerContainer.style.display = "none";
    mapMarkerContainer.style.opacity = 0;
    try {
      for (let i = 0; i < mapMarkers.length; i++) {
        const m = mapMarkers[i];
        const button = document.getElementById(`${m.id.slice(0, -6)}button`);
        const h =
          mapCanvas.width * 0.04 < 32
            ? 32
            : mapCanvas.width * 0.05 > 64
            ? mapCanvas.width * 0.05
            : 64;
        const w = h * 0.7;
        const left = m.lon * mapCanvas.width - w / 2;
        const top = m.lat * mapCanvas.height - h / 2;

        button.style.top = `${top}px`;
        button.style.left = `${left}px`;
        button.style.height = `${h}px`;
        button.style.width = `${w}px`;
        button.style.display = "block";
        button.dataset.tooltipTop = top + MAP_CONTAINER_Y;
        button.dataset.tooltipLeft = left + w / 2 + MAP_CONTAINER_X;
      }
      setTimeout(() => {
        mapMarkerContainer.style.display = "block";
        if (!ANIMATING_MAP) mapMarkerContainer.style.opacity = 1;
        resolve();
      }, 100);
    } catch (e) {
      reject(e);
    }
  });
}
function drawMap() {
  const mapRingImage = document.getElementById("island_ring_image");
  const mapIslandImage = document.getElementById("island_map_image");

  return new Promise((resolve, reject) => {
    Promise.all([drawImage(mapIslandImage), drawImage(mapRingImage)])
      .then(resolve)
      .catch(reject);
  });
}
function placeMapContainer(x, y) {
  return new Promise((resolve, reject) => {
    try {
      MAP_CONTAINER_X = parseInt(x);
      MAP_CONTAINER_Y = parseInt(y);

      // apply position to container
      mapContainer.style.left = `${MAP_CONTAINER_X}px`;
      mapContainer.style.top = `${MAP_CONTAINER_Y}px`;
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
function initWindowEvents() {
  let windowWidth = window.innerWidth;

  function handleWindowResize() {
    return new Promise((resolve, reject) => {
      try {
        if (window.innerWidth !== windowWidth) {
          windowWidth = window.innerWidth;
          resetMapContainer()
            .then(drawMap)
            .then(placeMarkerButtons)
            .then(resolve)
            .catch(reject);
        } else {
          resolve();
        }
      } catch (e) {
        reject(e);
      }
    });
  }
  return new Promise((resolve, reject) => {
    try {
      window.addEventListener("resize", handleWindowResize);
      window.addEventListener("orientationchange", handleWindowResize);
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
/* init functions */
function initMapMoveControls() {
  function initMouseControls() {
    mapComponent.addEventListener(
      "mousedown",
      e => {
        DRAGGING_MAP = true;
        mapContainer.style.cursor = "grabbing";
      },
      false
    );
    mapComponent.addEventListener(
      "mouseup",
      e => {
        mapContainer.style.cursor = "grab";
        DRAGGING_MAP = false;
      },
      false
    );
    mapComponent.addEventListener(
      "mouseleave",
      e => {
        mapContainer.style.cursor = "grab";
        DRAGGING_MAP = false;
      },
      false
    );
    mapComponent.addEventListener(
      "mousemove",
      e => {
        if (DRAGGING_MAP) {
          const minX = -parseInt(mapCanvas.width) * 0.75;
          const maxX = parseInt(mapCanvas.width) * 0.75;
          const minY = -parseInt(mapCanvas.height) * 0.5;
          const maxY = parseInt(mapCanvas.height) * 0.5;
          const moveX = MAP_CONTAINER_X + e.movementX;
          const moveY = MAP_CONTAINER_Y + e.movementY;
          const newX = Math.max(Math.min(maxX, moveX), minX);
          const newY = Math.max(Math.min(maxX, moveY), minY);

          placeMapContainer(newX, newY);
        }
      },
      false
    );
  }
  function initTouchControls() {
    let moving = false;

    mapComponent.addEventListener(
      "touchstart",
      e => {
        TOUCH_X = e.touches[0].pageX;
        TOUCH_Y = e.touches[0].pageY;
        DRAGGING_MAP = true;
      },
      false
    );
    mapComponent.addEventListener("touchend", e => {
      TOUCH_X = 0;
      TOUCH_Y = 0;
      DRAGGING_MAP = false;
    });
    mapComponent.addEventListener(
      "touchcancel",
      e => {
        DRAGGING_MAP = false;
      },
      false
    );
    mapComponent.addEventListener(
      "touchmove",
      e => {
        if (DRAGGING_MAP && e.touches.length === 1 && !moving) {
          console.log(e.touches[0]);
          moving = true;
          const minX = -window.innerWidth * 0.5;
          const maxX = window.innerWidth * 0.75;
          const minY = -window.innerHeight * 0.25;
          const maxY = window.innerHeight * 0.75;

          const dX = e.touches[0].clientX - TOUCH_X;
          const dY = e.touches[0].clientY - TOUCH_Y;
          TOUCH_X = e.touches[0].clientX;
          TOUCH_Y = e.touches[0].clientY;

          console.log(dX, dY);

          const moveX = MAP_CONTAINER_X + dX;
          const moveY = MAP_CONTAINER_Y + dY;
          const newX = Math.max(Math.min(maxX, moveX), minX);
          const newY = Math.max(Math.min(maxX, moveY), minY);

          placeMapContainer(newX, newY).then(() => {
            moving = false;
          });
        }
      },
      false
    );
  }
  return new Promise((resolve, reject) => {
    // dragging with a mouse
    try {
      initTouchControls();
      initMouseControls();
      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
function initMapControls() {
  ENABLE_ZOOM = true;

  function zoomMap(scale, speed = 60) {
    return new Promise((resolve, reject) => {
      if (scale <= 3 && scale >= 0.75) {
        try {
          tooltip.innerHTML = "";
          mapUI.className = "zooming";
          MAP_SCALE = scale;

          let dx = Math.floor(
            GET_DEFAULT_MAP_WIDTH() * MAP_SCALE - MAP_CONTAINER_WIDTH
          );
          let dy = Math.floor(dx * MAP_RATIO);

          animateMap(
            {
              x: MAP_CONTAINER_X - dx / 2,
              y: MAP_CONTAINER_Y - dy / 2,
              w: GET_DEFAULT_MAP_WIDTH() * MAP_SCALE
            },
            speed
          ).then(() => {
            mapUI.className = "";
            resolve();
          });
        } catch (e) {
          reject(e);
        }
      } else {
        resolve();
      }
    });
  }
  function createZoomButtons() {
    return new Promise((resolve, reject) => {
      try {
        const zoomInButton = document.getElementById("zoom_in_button");
        const zoomOutButton = document.getElementById("zoom_out_button");

        zoomInButton.addEventListener(
          "click",
          () => zoomMap(MAP_SCALE + 0.5),
          false
        );
        zoomOutButton.addEventListener(
          "click",
          () => zoomMap(MAP_SCALE - 0.5),
          false
        );

        mapUI.appendChild(zoomInButton);
        mapUI.appendChild(zoomOutButton);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }
  function initScrollZoom() {
    return new Promise((resolve, reject) => {
      try {
        mapComponent.addEventListener(
          "wheel",
          e => {
            if (e.deltaY > 0) {
              zoomMap(MAP_SCALE + 0.25, 10);
            } else {
              zoomMap(MAP_SCALE - 0.25, 10);
            }
          },
          false
        );
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  return new Promise((resolve, reject) => {
    try {
      Promise.all([initScrollZoom(), createZoomButtons()])
        .then(resolve)
        .catch(reject);
    } catch (e) {
      reject(e);
    }
  });
}
function initMenu() {
  const navMenuItems = document.getElementsByClassName("nav_menu_item");

  return new Promise((resolve, reject) => {
    try {
      for (let i = 0; i < navMenuItems.length; i++) {
        const m = navMenuItems[i];
        const storyTitle = m.id.slice(0, -4);
        const button = document.getElementById(`${storyTitle}_button`);

        navMenuItems[i].addEventListener(
          "mouseover",
          () => {
            button.dispatchEvent(MOUSE_OVER);
          },
          false
        );
        navMenuItems[i].addEventListener(
          "mouseleave",
          () => {
            button.dispatchEvent(MOUSE_LEAVE);
          },
          false
        );
        navMenuItems[i].removeEventListener("click", CLICK);
        navMenuItems[i].addEventListener("click", () => {
          if (storyTitle === "guided_tour") {
            navComponent.className = "hide";
            GUIDED_TOUR = true;
            document
              .getElementById("protection_prayer_button")
              .dispatchEvent(CLICK);
          } else {
            navComponent.className = "hide";
            button.dispatchEvent(CLICK);
          }
        });
      }
      navHeader.addEventListener(
        "click",
        () => {
          navComponent.className =
            navComponent.className === "show" ? "hide" : "show";
        },
        false
      );

      resolve();
    } catch (e) {
      reject(e);
    }
  });
}
function initPlayerControls(audio) {
  const playerTitle = document.getElementById("player_title");
  const playerButtons = document.getElementById("player_buttons");
  const playerProgressBar = document.getElementById("player_progress_bar");
  const playerTime = document.getElementById("player_time");
  const playButton = document.getElementById("play_button");
  const prevButton = document.getElementById("prev_button");
  const nextButton = document.getElementById("next_button");

  let playerClicked = false;
  let canPlayThrough = false;
  let ended = false;

  function updatePlayerTime() {
    function formatAudioTime(time) {
      return {
        minutes: Math.floor(time / 60),
        seconds: ("0" + parseInt(time % 60)).slice(-2)
      };
    }
    playerProgressBar.value = !CURRENT_AUDIO.currentTime
      ? 0
      : parseInt(CURRENT_AUDIO.currentTime);
    playerProgressBar.max = !CURRENT_AUDIO.duration
      ? 0
      : parseInt(CURRENT_AUDIO.duration);

    const current = formatAudioTime(CURRENT_AUDIO.currentTime);
    const duration = formatAudioTime(CURRENT_AUDIO.duration);

    playerTime.innerHTML = `${current.minutes}:${current.seconds}/${duration.minutes}:${duration.seconds}`;
  }

  CURRENT_AUDIO.addEventListener("error", e => {
    console.log("error", e);
  });
  CURRENT_AUDIO.addEventListener("emptied", () => {});
  CURRENT_AUDIO.addEventListener("ended", () => {
    if (GUIDED_TOUR === true && !ended) {
      ended = true;

      if (!playerClicked && CURRENT_INDEX + 1 < storyArticles.length) {
        playerClicked = true;
        playerButtons.className = "";
        playButton.className = "";
        playerTitle.innerHTML = "Loading...";
        playerTime.innerHTML = "--:--/--:--";

        const buttonId = `${storyArticles[CURRENT_INDEX + 1].id.slice(
          0,
          -5
        )}button`;
        canPlayThrough = false;

        document.getElementById(buttonId).dispatchEvent(CLICK);
        setTimeout(() => {
          playerClicked = false;
          playerButtons.className = "clickable";
        }, 300);
      }
    }
  });
  CURRENT_AUDIO.addEventListener("canplay", () => {
    //     if (GUIDED_TOUR === true) {
    //       CURRENT_AUDIO.play();
    //     }
  });
  CURRENT_AUDIO.addEventListener("canplaythrough", () => {
    canPlayThrough = true;
    playerTitle.innerHTML = `${playerUI.dataset.story_title}`;
    playerButtons.className = "clickable";
    playButton.className = "clickable";

    //     if (GUIDED_TOUR === true) {
    //       CURRENT_AUDIO.play();
    //     }
  });
  CURRENT_AUDIO.addEventListener("durationchange", updatePlayerTime);
  CURRENT_AUDIO.addEventListener("loadeddata", () => {
    playerTitle.innerHTML = `${playerUI.dataset.story_title}`;
  });
  CURRENT_AUDIO.addEventListener("pause", () => {
    playerUI.className = "paused";
    playerUI.dataset.playing = "false";
  });
  CURRENT_AUDIO.addEventListener("play", () => {
    playerUI.className = "playing";
    playerUI.dataset.playing = "true";
  });
  CURRENT_AUDIO.addEventListener("playing", () => {
    ended = false;
    playerTitle.innerHTML = `${playerUI.dataset.story_title}`;
  });
  CURRENT_AUDIO.addEventListener("progress", e => {
    playerTitle.innerHTML = `${playerUI.dataset.story_title}`;
    playButton.className = "clickable";

    if (CURRENT_INDEX === 0) {
      prevButton.className = "";
      nextButton.className = "clickable";
    } else if (CURRENT_INDEX + 1 === storyArticles.length) {
      prevButton.className = "clickable";
      nextButton.className = "";
    } else {
      prevButton.className = "clickable";
      nextButton.className = "clickable";
    }
  });
  CURRENT_AUDIO.addEventListener("timeupdate", updatePlayerTime);

  playButton.addEventListener("click", () => {
    if (!playerClicked) {
      playerClicked = true;
      playerButtons.className = "";
      setTimeout(() => {
        playerClicked = false;
        playerButtons.className = "clickable";
      }, 400);
      if (playerUI.dataset.playing === "true") {
        CURRENT_AUDIO.pause();
      } else if (playerUI.dataset.playing === "false") {
        CURRENT_AUDIO.play();
      }

      setTimeout(() => {
        playerClicked = false;
        playerButtons.className = "clickable";
      }, 300);
    }
  });
  prevButton.addEventListener("click", () => {
    if (!playerClicked && CURRENT_INDEX > 0) {
      playerClicked = true;
      playerButtons.className = "";
      playButton.className = "";
      playerTitle.innerHTML = "Loading...";
      playerTime.innerHTML = "--:--/--:--";

      const buttonId = `${storyArticles[CURRENT_INDEX - 1].id.slice(
        0,
        -5
      )}button`;
      canPlayThrough = false;

      document.getElementById(buttonId).dispatchEvent(CLICK);
      setTimeout(() => {
        playerClicked = false;
        playerButtons.className = "clickable";
      }, 300);
    }
  });
  nextButton.addEventListener("click", () => {
    if (!playerClicked && CURRENT_INDEX + 1 < storyArticles.length) {
      playerClicked = true;
      playerButtons.className = "";
      playButton.className = "";
      playerTitle.innerHTML = "Loading...";
      playerTime.innerHTML = "--:--/--:--";

      const buttonId = `${storyArticles[CURRENT_INDEX + 1].id.slice(
        0,
        -5
      )}button`;
      canPlayThrough = false;

      document.getElementById(buttonId).dispatchEvent(CLICK);
      setTimeout(() => {
        playerClicked = false;
        playerButtons.className = "clickable";
      }, 300);
    }
  });
}
function initSplash() {
  const splashComponent = document.getElementById("splash_component");
  const splashHandle = document.getElementById("splash_handle");

  //   function splashPromise() {
  //     return new Promise((resolve, reject) => {
  //       const handler = () => {
  //         splashHandle.removeEventListener("click", handler);
  //         window.removeEventListener("scroll", handler);
  //         splashComponent.removeEventListener("wheel", handler);
  //         splashComponent.className = "hide";
  //         resolve();
  //       };

  //       try {
  //         splashComponent.addEventListener("wheel", handler, false);
  //         window.addEventListener("scroll", handler, false);
  //         splashHandle.addEventListener("click", handler, false);
  //       } catch (e) {
  //         throw e;
  //       }
  //     });
  //   }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      splashComponent.className = "hide";
      resolve();
    }, 1000);
  });
}
function initAbout() {
  const hideButton = document.createElement("button");

  aboutComponent.insertAdjacentElement("afterbegin", hideButton);
  hideButton.className = "hide_about_button";
  hideButton.addEventListener("click", () => {
    resetMapContainer()
      .then(drawMap)
      .then(placeMarkerButtons)
      .then(() => {
        hideUI();
        aboutComponent.className = "";
      });
  });
}
function initWelcome() {
  const welcomeComponent = document.getElementById("welcome_component");
  const hideWelcomeButton = document.getElementById("hide_welcome");
  const aboutAnchor = document.getElementById("about_button");
  const exploreAnchor = document.getElementById("explore_button");
  const guidedTourAnchor = document.getElementById("guided_tour_button");

  function hideWelcome() {
    //     return new Promise((resolve, reject) => {
    try {
      welcomeComponent.className = "hide";
      mapComponent.className = "";
      //         resolve();
    } catch (e) {
      console.log(e);
    }
    //     });
  }
  //     return new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //   hideWelcomeButton.addEventListener(
  //     "click",
  //     () => {
  //       hideWelcome().then(resolve).catch(reject);
  //     },
  //     false
  //   );
  //   exploreAnchor.addEventListener(
  //     "click",
  //     () => {
  //       hideWelcome().then(resolve).catch(reject);
  //     },
  //     false
  //   );
  aboutAnchor.addEventListener(
    "click",
    () => {
      //       hideWelcome();
      //         .then(() => {
      hideUI();
      const navHeaderRect = navHeader.getBoundingClientRect();

      aboutComponent.className = "show";
      aboutComponent.style.marginTop = `${navHeaderRect.height}px`;
      //           resolve();
      //         })
      //         .catch(reject);
    },
    false
  );
  guidedTourAnchor.addEventListener(
    "click",
    () => {
      //       hideWelcome();
      //         .then(() => {
      const button = document.getElementById("protection_prayer_button");

      if (button !== null) {
        GUIDED_TOUR = true;
        button.dispatchEvent(CLICK);
      }
      //           resolve();
      //         })
      //         .catch(reject);
    },
    false
  );
  hideWelcome();
  //     }, 1000);
  //   });
}
document.addEventListener("DOMContentLoaded", () => {
  loadAllImages()
    .then(resetMapContainer)
    .then(drawMap)
    .then(() => {
      document.body.className = "ready";
      mapComponent.className = "heavy_blur";
    })
    .then(initWindowEvents)
    .then(initSplash)
    .then(initWelcome)
    .then(createMarkerButtons)
    .then(placeMarkerButtons)
    .then(() => {
      setTimeout(() => {
        initAbout();
        initMapMoveControls();
        initMapControls();
        initPlayerControls();
        initMenu();
      }, 500);
    })
    .then(() => {
      if (GUIDED_TOUR === true) {
        document.getElementById("protection_prayer_button").dispatchEvent(CLICK);
      }
    })
    .catch(e => {
      console.log(e);
      alert(e);
    });
});
